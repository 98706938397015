export { _createClass as createClass, _extends as extends, _inheritsLoose as inheritsLoose };

function _defineProperties(target, props) {
 for (var i = 0; i < props.length; i++) {
 var descriptor = props[i];
 descriptor.enumerable = descriptor.enumerable || false;
 descriptor.configurable = true;
 if ("value" in descriptor) descriptor.writable = true;
 Object.defineProperty(target, descriptor.key, descriptor);
 }
}

function _createClass(Constructor, protoProps, staticProps) {
 if (protoProps) _defineProperties(Constructor.prototype, protoProps);
 if (staticProps) _defineProperties(Constructor, staticProps);
 return Constructor;
}

function _extends() {
 _extends = Object.assign || function(target) {
 for (var i = 1; i < arguments.length; i++) {
 var source = arguments[i];

 for (var key in source) {
 if (Object.prototype.hasOwnProperty.call(source, key)) {
 target[key] = source[key];
 }
 }
 }

 return target;
 };

 return _extends.apply(this, arguments);
}

function _inheritsLoose(subClass, superClass) {
 subClass.prototype = Object.create(superClass.prototype);
 subClass.prototype.constructor = subClass;
 subClass.__proto__ = superClass;
}