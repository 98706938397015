import $ from 'jquery'

/**
 * --------------------------------------------------------------------------
 * Bootstrap (v4.0.0-beta.3): util.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

const Util = (($) => {


 /**
 * ------------------------------------------------------------------------
 * Private TransitionEnd Helpers
 * ------------------------------------------------------------------------
 */

 let transition = false

 const MAX_UID = 1000000

 // shoutout AngusCroll (https://goo.gl/pxwQGp)
 function toType(obj) {
 return {}.toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
 }

 function getSpecialTransitionEndEvent() {
 return {
 bindType: transition.end,
 delegateType: transition.end,
 handle(event) {
 if ($(event.target).is(this)) {
 return event.handleObj.handler.apply(this, arguments) // eslint-disable-line prefer-rest-params
 }
 return undefined // eslint-disable-line no-undefined
 }
 }
 }

 function transitionEndTest() {
 if (window.QUnit) {
 return false
 }

 return {
 end: 'transitionend'
 }
 }

 function transitionEndEmulator(duration) {
 let called = false

 $(this).one(Util.TRANSITION_END, () => {
 called = true
 })

 setTimeout(() => {
 if (!called) {
 Util.triggerTransitionEnd(this)
 }
 }, duration)

 return this
 }

 function setTransitionEndSupport() {
 transition = transitionEndTest()

 $.fn.emulateTransitionEnd = transitionEndEmulator

 if (Util.supportsTransitionEnd()) {
 $.event.special[Util.TRANSITION_END] = getSpecialTransitionEndEvent()
 }
 }

 function escapeId(selector) {
 // we escape IDs in case of special selectors (selector = '#myId:something')
 // $.escapeSelector does not exist in jQuery < 3
 selector = typeof $.escapeSelector === 'function' ? $.escapeSelector(selector).substr(1) :
 selector.replace(/(:|\.|\[|\]|,|=|@)/g, '\\$1')

 return selector
 }

 /**
 * --------------------------------------------------------------------------
 * Public Util Api
 * --------------------------------------------------------------------------
 */

 const Util = {

 TRANSITION_END: 'bsTransitionEnd',

 getUID(prefix) {
 do {
 // eslint-disable-next-line no-bitwise
 prefix += ~~(Math.random() * MAX_UID) // "~~" acts like a faster Math.floor() here
 } while (document.getElementById(prefix))
 return prefix
 },

 getSelectorFromElement(element) {
 let selector = element.getAttribute('data-target')
 if (!selector || selector === '#') {
 selector = element.getAttribute('href') || ''
 }

 // if it's an ID
 if (selector.charAt(0) === '#') {
 selector = escapeId(selector)
 }

 try {
 const $selector = $(document).find(selector)
 return $selector.length > 0 ? selector : null
 } catch (error) {
 return null
 }
 },

 reflow(element) {
 return element.offsetHeight
 },

 triggerTransitionEnd(element) {
 $(element).trigger(transition.end)
 },

 supportsTransitionEnd() {
 return Boolean(transition)
 },

 isElement(obj) {
 return (obj[0] || obj).nodeType
 },

 typeCheckConfig(componentName, config, configTypes) {
 for (const property in configTypes) {
 if (Object.prototype.hasOwnProperty.call(configTypes, property)) {
 const expectedTypes = configTypes[property]
 const value = config[property]
 const valueType = value && Util.isElement(value) ?
 'element' : toType(value)

 if (!new RegExp(expectedTypes).test(valueType)) {
 throw new Error(
 `${componentName.toUpperCase()}: ` +
 `Option "${property}" provided type "${valueType}" ` +
 `but expected type "${expectedTypes}".`)
 }
 }
 }
 }
 }

 setTransitionEndSupport()

 return Util

})($)

export default Util
